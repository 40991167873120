const data = [{
    name: 'ahorn',
    question: 'Von dieser Gattung gibt es 100 bis 200 Arten. Welche Art ist gesucht?',
    choices: [
      'linde',
      'ahorn',
      'eiche',
      'buche',
    ],
    images: [{
        src: require('../assets/ahornbaum/maple-trees-3418766_640.jpg'),
        alt: "Ahornbäume im Ahornboden im Karwendel"
      },
      {
        src: require('../assets/ahornbaum/autumn-5681066_640.jpg'),
        alt: "Herbstliches Ahornblatt"
      },
      {
        src: require('../assets/ahornbaum/maple-tree-557407_640.jpg'),
        alt: "Ahornbaum mit herbstlich gefärbten gelben Blättern"
      },
      {
        src: require('../assets/ahornbaum/tree-1470257_640.jpg'),
        alt: "Rinde eines Ahornbaums"
      }
    ],
  },
  {
    name: 'eiche',
    question: 'Dieser Baum gehört zur Familie der Buchengewächse.',
    choices: [
      'ahorn',
      'buche',
      'fichte',
      'eiche',
    ],
    images: [{
        src: require('../assets/eiche/tree-893273_640.jpg'),
        alt: "Alte Eiche mit vielen Ästen und grünen Blättern"
      },
      {
        src: require('../assets/eiche/tree-402953_640.jpg'),
        alt: "Eiche auf grüner Wiese in schöner Natur"
      },
      {
        src: require('../assets/eiche/oak-leaves-1777410_640.jpg'),
        alt: "Blatt einer Eiche in Herbstfarben"
      },
      {
        src: require('../assets/eiche/acorn-3632517_640.jpg'),
        url: "Grüne Eicheln hängen an einem Zweig"
      },
    ],
  },
  {
    name: 'fichte',
    question: 'Und welcher Baum ist das?',
    choices: [
      'tanne',
      'kiefer',
      'fichte',
      'eibe',
    ],
    images: [{
        src: require('../assets/fichte/forest-4032425_640.jpg'),
        alt: "Wald mit Fichten nach oben blickend"
      },
      {
        src: require('../assets/fichte/pine-4210810_640.jpg'),
        alt: "Fichtenzweig mit Nadeln"
      },
      {
        src: require('../assets/fichte/bark-3763744_640.jpg'),
        alt: "Rinde eines Fichtenstamms"
      },
      {
        src: require('../assets/fichte/pine-branch-3382894_640.jpg'),
        alt: "Knospen eines Fichtenzweigs"
      },
    ],
  },
  {
    name: 'linde',
    question: 'Ein weiterer einheimischer Laubbaum',
    choices: [
      'birke',
      'eibe',
      'linde',
      'eiche',
    ],
    images: [
      {
        src: require('../assets/linde/tree-4637270_640.jpg'),
        alt: "Lindenbaum im Herbstlicht"
      },
      {
        src: require('../assets/linde/leaf-55859_640.jpg'),
        alt: "Lindenblatt vor grünem Hintergrund"
      },
      {
        src: require('../assets/linde/linden-tree-817061_640.jpg'),
        alt: "Blüten eines Lindenbaums"
      },
      {
        src: require('../assets/linde/log-1029966_640.jpg'),
        alt: "Lindenstamm mit Blättern"
      },
    ],
  },
  {
    name: 'birke',
    question: 'Welcher Baum ist das?',
    choices: [
      'birke',
      'ahorn',
      'eiche',
      'weide',
    ],
    images: [{
        src: require('../assets/birke/birch-792054_640.jpg'),
        alt: "Birkenbäume im Wald im Gegenlicht"
      },
      {
        src: require('../assets/birke/birch-4511175_640.jpg'),
        alt: "Birkenblätter"
      },
      {
        src: require('../assets/birke/schalbirke-4077322_640.jpg'),
        alt: "Rinde einer Birke"
      },
      {
        src: require('../assets/birke/birch-seeds-2230840_640.jpg'),
        alt: "Birkensamen"
      },
    ],
  },
  {
    name: 'esche',
    question: 'Und der hier?',
    choices: [
      'birke',
      'eiche',
      'weide',
      'esche',
    ],
    images: [{
        src: require('../assets/esche/trees-329314_640.jpg'),
        alt: "Zwei Eschen auf Feld mit Feldweg"
      },
      {
        src: require('../assets/esche/tree-3732679_640.jpg'),
        alt: "Große Esche auf Wiese"
      },
      {
        src: require('../assets/esche/tree-5206507_640.jpg'),
        alt: "Blätter einer Esche"
      },
      {
        src: require('../assets/esche/flowering-5055169_640.jpg'),
        alt: "Blütenstand einer Esche"
      },
    ]
  },
  {
    name: 'laerche',
    question: 'Noch ein Nadelbaum?',
    choices: [
      'fichte',
      'tanne',
      'kiefer',
      'laerche',
    ],
    images: [{
        src: require('../assets/laerche/lake-5776561_640.jpg'),
        alt: "Lärchen am Ufer eines Gebirgsees"
      },
      {
        src: require('../assets/laerche/conifers-5588161_640.jpg'),
        alt: "Lärchenzweig mit Zapfen und Nadeln"
      },
      {
        src: require('../assets/laerche/nature-402657_640.jpg'),
        alt: "Zweig einer Lärche mit vielen Zapfen"
      },
    ],
  },
  {
    name: 'eibe',
    question: 'Und noch ein Nadelbaum',
    choices: [
      'eibe',
      'kiefer',
      'laerche',
      'tanne',
    ],
    images: [{
        src: require('../assets/eibe/yew-4795118_640.jpg'),
        alt: "Eibe im Winter mit Frost auf den Zweigen"
      },
      {
        src: require('../assets/eibe/yew-4559930_640.jpg'),
        alt: "Rote und grüne Beeren hängen an den Zweigen einer Eibe"
      },
    ],
  },
  {
    name: 'buche',
    question: 'Bekannter Hartholzbaum',
    choices: [
      'esche',
      'linde',
      'buche',
      'laerche',
    ],
    images: [{
        src: require('../assets/buche/forest-524017_640.jpg'),
        alt: "Wald aus Buchen"
      },
      {
        src: require('../assets/buche/deciduous-tree-5331975_640.jpg'),
        alt: "Buche an einem Weiher im Gegenlicht"
      },
      {
        src: require('../assets/buche/nature-3757377_640.jpg'),
        alt: "Bucheblätter im Streiflicht"
      },
    ]
  },
  {
    name: 'erle',
    question: 'Welcher Baum ist das?',
    choices: [
      'esche',
      'birke',
      'kiefer',
      'erle',
    ],
    images: [{
        src: require('../assets/erle/tree-3104438_640.jpg'),
        alt: "Erle am Ufer eines Sees"
      },
      {
        src: require('../assets/erle/alder-262637_640.jpg'),
        alt: "Blätter einer Erle"
      },
      {
        src: require('../assets/erle/black-alder-4681232_640.jpg'),
        alt: "Samen einer Erle"
      },
    ],
  },
  {
    name: 'kastanie',
    question: 'Der beliebteste Baum in deutschen Biergärten:',
    choices: [
      'linde',
      'kastanie',
      'birke',
      'kirschbaum',
    ],
    images: [{
        src: require('../assets/kastanie/nature-321862_640.jpg'),
        alt: "Kastanienwald aus Froschperspektive"
      },
      {
        src: require('../assets/kastanie/chestnut-tree-3391500_640.jpg'),
        alt: "Blühender Kastanienbaum"
      },
      {
        src: require('../assets/kastanie/leaf-3332378_640.jpg'),
        alt: "Kastanienblätter mit Kastanienbäumen im Hintergrund"
      },
      {
        src: require('../assets/kastanie/chestnut-2581338_640.jpg'),
        alt: "Grüne Kastanien mit Stacheln an einem Kastanienzweig"
      },
    ],
  },
  {
    name: 'weide',
    question: 'Sie gehört zu einer Gattung, die ca. 450 Arten umfasst. Dazu gehören Bäume, Sträuche und Zwergsträucher.',
    choices: [
      'esche',
      'birke',
      'erle',
      'weide',
    ],
    images: [{
        src: require('../assets/weide/tree-984846_640.jpg'),
        alt: "Weide an einem Teich"
      },
      {
        src: require('../assets/weide/pasture-3704724_640.jpg'),
        alt: "Weide direkt an einem kleinen See"
      },
      {
        src: require('../assets/weide/willow-960769_640.jpg'),
        alt: "Zweige einer Weide mit den charakteristischen länglichen Blättern"
      },
    ],
  },
  {
    name: 'pappel',
    question: 'Welche dieser Arten wird häufig zur Gewinnung von Holz, Papier und Energie verwendet?',
    choices: [
      'pappel',
      'birke',
      'erle',
      'weide',
    ],
    images: [{
        src: require('../assets/pappel/plane-tree-337780_640.jpg'),
        alt: "Pappel mit dem typischen hellen Stamm"
      },
      {
        src: require('../assets/pappel/poplar-1758789_640.jpg'),
        alt: "Pappelzweig mit Blüten"
      },
      {
        src: require('../assets/pappel/leaf-671661_640.jpg'),
        alt: "Blatt einer Pappel"
      },
    ],
  }
]
export default data;